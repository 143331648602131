.about-page {
  padding: 20px;
  max-width: 800px; /* Limit the width */
  width: 100%; /* Ensure it takes full width up to max-width */
  margin: 0 auto; /* Center the content */
  line-height: 1.6; /* Optional for better readability */
  background-color: #fff; /* Optional background color */
  box-sizing: border-box; /* Ensures padding doesn’t increase the width */
}

.founders-photo {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.founders-photo img {
  max-width: 100%; /* Ensures the image is responsive */
  height: auto; /* Maintain aspect ratio */
  max-height: 300px; /* Limit the height for visual balance */
  object-fit: contain; /* Ensures the image fits without being distorted */
}
