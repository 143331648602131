.brand-collage {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(150px, 1fr)
  ); /* Adjust number of columns */
  gap: 20px;
  justify-items: center;
  align-items: center;
  padding: 20px;
}

.brand-item img {
  max-width: 100%;
  max-height: 100px;
  object-fit: contain; /* Ensures the images maintain aspect ratio */
}
